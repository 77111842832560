import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class GoogleReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviews: [], // Stato iniziale vuoto per le recensioni
        };
    }

    componentDidMount() {
        // Controlla che Google Maps API sia caricata
        if (!window.google) {
            console.error("Google Maps API non è caricata correttamente.");
            return;
        }

        const placeId = "ChIJxZa7oXUphEcRx4qhsewnqa0"; // ID dell'attività
        const map = new window.google.maps.Map(document.createElement("div")); // Mappa invisibile

        const request = {
            placeId: placeId,
            fields: ["reviews"], // Richiedi solo il campo delle recensioni
        };

        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const reviews = (place.reviews || []).map((r) => ({
                    name: r.author_name,
                    rating: r.rating,
                    comment: r.text,
                    url: r.author_url, // URL della recensione
                }));

                // Usa setState per aggiornare lo stato e attivare il re-render
                this.setState({ reviews });
            } else {
                console.error("Errore nel recupero delle recensioni:", status);
            }
        });
    }

    // Funzione per troncare il testo con ellissi
    truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
    };

    render() {
        // Impostazioni per il carosello
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: true,
        };

        return (
            <div id="testimonials" className="block spacer p-top-xl">
                <div className="title text-center">
                    <h2 className="hr">Parlano di noi</h2>
                </div>

                <div className="adv-slider-reviews pt-0 pb-4">
                    <Slider {...settings}>
                        {this.state.reviews.map((review, index) => (
                            <div key={index} className="reviews-text-item">
                                <div className="reviews-text-item-content px-5">
                                    {/* Troncamento del commento */}
                                    <a
                                        href="https://www.google.com/search?sa=X&sca_esv=334922e174cf79f6&tbm=lcl&sxsrf=ADLYWILz8keA-QYLk-BCOhluN_fqOffuwA:1732978088736&q=Salone+Borgo+d%27Oro+Recensioni&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNDI1NDY1N7M0Njc0NzQ1NbI0M9_AyPiKUTY4MSc_L1XBKb8oPV8hRd2_KF8hKDU5Na84Mz8vcxErfnkADLR5P1sAAAA&rldimm=12513576937171552967&hl=it-IT&ved=2ahUKEwjLt4e7poSKAxXQ3QIHHaiEK5wQ9fQKegQIRxAF&biw=1920&bih=945&dpr=1#lkt=LocalPoiReviews"//{review.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <p>
                                            {this.truncateText(review.comment, 200)}
                                        </p>
                                    </a>
                                    {/* Stelle di valutazione */}
                                    <p className="my-2">
                                        {"★".repeat(review.rating) + "☆".repeat(5 - review.rating)}
                                    </p>
                                    {/* Nome dell'autore */}
                                    <p className="reviews-name pb-3">{review.name}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default GoogleReviews;
