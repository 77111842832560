import React from 'react';

const AboutsUs = () => {
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className={window.location.pathname != '/about-us' ? "wrapper" : "wrapper p-0"}>
                <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h2 style={{ fontSize: '40px' }}>Fondato nel 2024 il Salone Borgo d'Oro rappresenta il culmine dell'esperienza di Germano, ciò che ha contribuito a costruire la sua reputazione nel Mendrisiotto. Insieme a Jessica e Chiara, il nostro team di esperti è il cuore pulsante del salone, garantendo competenza e passione che rendono ogni visita un'esperienza unica.</h2>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p style={{ fontSize: '25px' }}>Un'elevata e costante formazione è la colonna portante del nostro team. Grazie a un continuo aggiornamento, siamo in grado di rimanere innovativi, portando non solo competenza, ma anche fresche novità nel nostro salone. Ci impegniamo a soddisfare al meglio le esigenze dei nostri clienti, offrendo loro un'esperienza all'insegna della qualità e della professionalità.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutsUs;
