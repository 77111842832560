import React from 'react';

const Contacts = () => {
    return (
        <div className="contacts">
            <div className="contacts-items">

                <div className="contacts-item">
                    <div className="contacts-item-title mb-2">
                        <h6>Telefono</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p>
                            <a href="tel:0041916461351">+41 91 646 13 51</a>
                        </p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title mb-2">
                        <h6>Email</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p>
                            <a href="mailto:company@domain.com">saloneborgodoro@gmail.com</a>
                        </p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title mb-2">
                        <h6>Social</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p>
                            <a title="Instagram" href="https://www.instagram.com/saloneborgodoro/" target='_blank'><img style={{ width: '20px', marginRight: '3px', marginBottom: '4px' }} src='assets/icons/instagram.png' alt='instagram'></img>Instagram</a>
                        </p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title mb-2">
                        <h6>Indirizzo</h6>
                    </div>
                    <div className="contacts-item-description">
                        <a style={{textDecoration:'underline'}} href="https://www.google.it/maps/place/Salone+Borgo+d'Oro/@45.8704709,8.9841235,17z/data=!3m1!4b1!4m6!3m5!1s0x47842975a1bb96c5:0xada927ecb1a18ac7!8m2!3d45.8704672!4d8.9866984!16s%2Fg%2F11vxyhqbhv?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D" target='_blank'><p>Via Luigi Lavizzari 21, 6850 Mendrisio, Svizzera</p></a>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Contacts;
